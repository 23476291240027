import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, faPlus, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Audiol from "../assets/images/Audiol.svg";
import Playicon from "../assets/images/playicon.svg";
import Download from "../assets/images/download-arrow.svg";
import { useHistory } from "react-router";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const Myrequest = () => {

    const history = useHistory();
    const jsonConfig = require("../config/Config.json");
    const token=localStorage.getItem('authToken')
    const [myIssueList, setmyIssueList] = useState(Array);
    

    
    useEffect(() => {
        myList();
  }, []);
  
    

const myList=()=>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
          
        axios.get(jsonConfig.apibaseurl + "customer/issue/view",{
            headers: headers
        }).then((response) => {
            setmyIssueList(response.data.data)
        }, (error) => {
        })
    
    
}
    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">

                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>My Issues</h6>
                        <h2>My Request</h2>
                        {/* <p>Raise a request regarding your problem</p> */}
                    </div>
                <div className="col-sm-7 mx-auto">
                    <ul className="ourcomp helpbox">
                    {myIssueList.map((data,i)=>(
                        <li 
                            className="wow fadeIn" 
                            data-wow-offset="10"  
                            data-wow-iteration="1" 
                            data-wow-delay=".5s"
                            key={i}
                            >
                            <div className="audiolist mb-2 myrequest"> 
                                <p>Ticket No : {data.ticket}</p>
                                <p>Raised Date : {new Date(data.issue_date).toLocaleDateString()+","+new Date(data.issue_date).toLocaleTimeString()}</p>
                                <p>Closure Date : {data.close_date===null?'':new Date(data.close_date).toLocaleDateString()+","+new Date(data.close_date).toLocaleTimeString()}</p>
                                <p>Status : <span className="reopen">{data.status===true?'Open':'Closed'}</span></p>
                            </div>
                        </li>
                    ))}
                    </ul>
                    </div>
  
                                
                            </div>
					

					
				</div>
			
			</div>
           

        </div>
        
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Myrequest
