import React, { Component, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Audiol from "../assets/images/Audiol.svg";
import Playicon from "../assets/images/playicon.svg";
import Pauseicon from "../assets/images/pauseicon.svg";
import Check from "../assets/images/check.svg";
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { PayPalScriptProvider, PayPalButtons,BraintreePayPalButtons } from "@paypal/react-paypal-js";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";



  const Paypal = () => {

    const jsonConfig = require("../config/Config.json");
    const history = useHistory();
    const location = useLocation();
    const token=localStorage.getItem('authToken')

    const PAYMENT_CURRENCY = "USD";
    const PLAN_PRICE=location.state.plan_price;
    const amount = {
      currency_code: PAYMENT_CURRENCY,
      value: location.state.plan_price
    };
    
    const createOrder=(data, actions)=> {
      // throw new Error("force the createOrder callback to fail");
      return actions.order.create({

        purchase_units: [
          {
            amount: {
              value: PLAN_PRICE
            }
          }
        ]
        // purchase_units: [
        //   {
        //     amount,
        //     shipping: {
        //        //name: "Hello Brother",
        //        //method: "United States Postal Service",
        //       name: {
        //         full_name: "John"
        //       },
        //       type: "SHIPPING",
        //       // address: {
        //       //   address_line_1: "6/185",
        //       //   address_line_2: "kolkata",
        //       //   admin_area_2: "asd",
        //       //   admin_area_1: "WB",
        //       //   postal_code: "700075",
        //       //   country_code: "IN"
        //       // }
        //     }
        //   }
        // ]
        // application_context: {
        //   shipping_preference: "NO_SHIPPING"
        // }
      });
    }
    
    const oninit=(data, actions)=> {
      // actions.disable();
      // return actions.disable();
      
    }
    const onClick=(data, actions)=> {
      // actions.disable();
      
      // return actions.resolve();
    }
    const onApprove=(data, actions)=> {
      
      actions.order.capture().then((details) => {
        

        var paymentData={
          "create_time": convertDate(new Date()),
          "transactionid": details.id,
          "state": details.status,
          "plan_id" :location.state.plan_id,
          "transaction_amount": location.state.plan_price,
        }
          
            const headers = {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' + token
              }
            axios.post(jsonConfig.apibaseurl + "customer/checkout",paymentData,{
                headers: headers
            }).then((response) => {
                
                if(response.data.status){
                  NotificationManager.success(response.data.msg)
                  setTimeout(()=> {     
                    history.push('/thankyou')
                    }, 1500);
                  
                }
                else{
                  NotificationManager.error(response.data.msg)
                }
            }, (error) => {


            })
        
      });
    }
    const onError=(err)=> {
      console.error("error from the onError callback", err);
    }
    const convertDate=(date)=> {
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth()+1).toString();
      var dd  = date.getDate().toString();
    
      var mmChars = mm.split('');
      var ddChars = dd.split('');
    
      return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
    }
    return (
      <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">
						
						
                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                         <h6>Paypal Payment</h6>
                        {/*<h2>Frequency and tone libery</h2>
                        <p>Please go through the tones and pick the one that you like the best. 
                  Then press the check to select. Simple.</p> */}
                    </div>
                    <div className="col-sm-8 mx-auto">
                        
                          <PayPalScriptProvider 
                            options={{ 
                              //"client-id": "AWQsljh1nh3BA15OPCpTwa0vqtrudN2xj6bv8lBVGHUV6bGnC7uFpSZczrbfvIGGKGkBkWPyw0FOFXxV",
                              "client-id": "AcPM610gMAh87lcQCaziFEmGKGVpy-CXMOjInPcZdL-yx-4KK-jAPMsdkgYjDjYMlJ6U7YHaA9ObJYvP",
                              
                              // intent: "authorize",
                              // commit: false,
                              currency: "USD"
                            }}>
                            <PayPalButtons
                              style={{ color: "white", label: "checkout" }}
                              createOrder={createOrder}
                            //  // onClick={onClick}
                            //   //onShippingChange={onShippingChange}
                              onApprove={onApprove}
                              onError={onError}
                              onInit={oninit}
                            />
                          </PayPalScriptProvider>


                    </div>
  
                                
                </div>
					

					
				</div>
			
			</div>
           

        </div>
        <NotificationContainer/>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
      
      );
};



  export default Paypal

