import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Audiol from "../assets/images/Audiol.svg";
import Playicon from "../assets/images/playicon.svg";
import Download from "../assets/images/download-arrow.svg";
import { useHistory } from "react-router";
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';


const Help = () => {

    const history = useHistory();
    const jsonConfig = require("../config/Config.json");
    const [affirmation, setaffirmation] = useState(Array);

    const getAllAffirmation=()=>{
        axios.get(jsonConfig.apibaseurl + "affirmation").then((response) => {
            setaffirmation(response.data.output)
        }, (error) => {


        })
    }
    const gotopage = () => {
        history.push('/subscription');
    }
    useEffect(() => {
        getAllAffirmation();
  }, []);

    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">

                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Affirmation</h6>
                        <h2>Example of Affirmation</h2>
                        <p>By definition your affirmation will be personal to you, and specific to what you want to achieve or change, but the following examples may provide some inspiration</p>
                    </div>
                <div className="col-sm-7 mx-auto">
                    <ul className="ourcomp helpbox">
                    {affirmation.map((data,i)=>(
                        <li 
                            className="wow fadeIn" 
                            data-wow-offset="10"  
                            data-wow-iteration="1" 
                            data-wow-delay=".5s"
                            key={i}
                        >
                            <div className="audiolist d-flex justify-content-start align-items-center mb-2"> 
                                <FontAwesomeIcon icon={faCircle} className="hcercl" />
                                <h4>{data.affirmation}</h4>
                            </div>
                        </li>
                    ))}
                    </ul>
                    </div>
  
                                
                            </div>
					

					
				</div>
			
			</div>
           

        </div>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Help
