import React from 'react'

import NavbarLogin from "../components/NavbarLogin";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Services from "../components/Services";
import Howitwork from '../components/Howitwork';
import Solution from '../components/Solution';
import Clint from '../components/Clint';
import Platfrom from '../components/Platfrom';
import Footer from '../components/Footer';


const HomePage = () => {

    return (
        <>
        {localStorage.getItem('authToken')==='' ||
        localStorage.getItem('authToken')===null ||
        localStorage.getItem('authToken')==='null' ||
        localStorage.getItem('authToken')===undefined ||
        localStorage.getItem('authToken')==='undefined'?(
            <NavbarLogin/>
        ):(
            <Navbar/>
        )}
        <Banner/>
        <Services/>
        <Howitwork/>
        <Solution/>
        <Platfrom/>
        <Footer/>
        </>
    )
}

export default HomePage
