import React from 'react'
// import logo from "../assets/images/logo.webp";
// import facebook from "../assets/images/facebook.svg";
// import twitter from "../assets/images/twitter.svg";
// import linkedin from "../assets/images/linkedin.svg";
// import pinterest from "../assets/images/pinterest.svg";
import fotterbg from "../assets/images/Footerbg.svg";
import Googleplay from "../assets/images/googlepay.svg";
import Iosapp from "../assets/images/iosapp.svg";
import Whitelogo from "../assets/images/whitelogo.svg";
import { Link } from "react-router-dom";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {

    return (
        <footer className="footer-sec text-white" style={{ backgroundImage: `url(${fotterbg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 pr-sm-5">
                        <div className="footer-sec-inner footer-1">
                            <a href="/"> <img src={Whitelogo} alt="Whitelogo" /> </a>
                            <p className="text-white">Using your own voice along with a meditation tone you are able to create a personalized Mind Track
                            </p>
                            {/* <div className="social-media-links">
                            <a href="#"><img src={facebook} alt="" width="40" /> </a>
                            <a href="#"><img src={twitter} alt="" width="40" /> </a>
                            <a href="#"><img src={linkedin} alt="" width="40" /> </a>
                            <a href="#"><img src={pinterest} alt="" width="40" /> </a>
                        </div> */}
                        </div>
                    </div>
                    <div className="col-md-2 col-6">
                        {/* <div className="footer-sec-inner">
                        <h4>Quick Links</h4>
                        <ul className="footer-list">
                            <li><a href="#">Home</a> </li>
                            <li><a href="#">Create tone</a> </li>
                            <li><a href="#">Tone Libery</a> </li>
                            <li><a href="#">Download</a> </li>
                            <li><a href="#">Subscription</a> </li>
                        </ul>
                    </div> */}
                    </div>
                    <div className="col-md-3 col-6">
                        {/* <div className="footer-sec-inner">
                        <h4>Product Help</h4>
                        <ul className="footer-list">
                            <li><a href="#">Help</a> </li>
                            <li><a href="#">Login</a> </li>
                            <li><a href="#">Privacy Policy</a> </li>
                            <li><a href="#">Terms & Conditions</a> </li>
                            <li><a href="#">Contact</a> </li>

                        </ul>
                    </div> */}
                    </div>
                    <div className="col-md-3">
                        <div className="footer-sec-inner">
                            <h4>Download</h4>
                            <Link to="https://play.google.com/store/apps/details?id=com.pan3"><img src={Googleplay} alt="Googleplay" className="mb-sm-4" /></Link>
                            <img src={Iosapp} alt="Iosapp" />
                        </div>
                    </div>
                </div>

                <div className="text-center mt-3 pt-4 copyright">
                    <p>© Copyrights {new Date().getFullYear()} pan3 app All rights reserved.</p>
                </div>
            </div>

        </footer>
    )
}

export default Footer
