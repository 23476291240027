import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser,faMicrophone,faCheck,faTimes,faPlay,faStop } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Recordwave from "../assets/images/recordwave.svg";
import Pauseicon from "../assets/images/pauseicon.svg";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { useHistory } from "react-router";
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import {NotificationContainer, NotificationManager} from 'react-notifications';

const Recordtone = () => {

    const jsonConfig = require("../config/Config.json");
    const history = useHistory();
    const location = useLocation();
    const token=localStorage.getItem('authToken')

    const [customSlNo, setcustomSlNo] = useState(0); 
    const [recordState, setrecordState] = useState("");
    const [audioData, setaudioData] = useState("");
    const [recorderTimer, setrecorderTimer] = useState(0);
    const [recordName, setrecordName] = useState("");
    const [recorderStatus, setrecorderStatus] = useState(false);
    const [base64File, setbase64File] = useState("");

    const start = () => {
        setrecordState(RecordState.START);
        setrecorderStatus(true);
        setrecordName('');
        createTimer();
	  }
	
	  const pause = () => {
        setrecordState(RecordState.PAUSE)
	  }
	
	  const stop = () => {
        setrecordState(RecordState.STOP);
        setrecorderStatus(false)
	  }
	
	  const onStop = (data) => {
        setaudioData(data);
        var myfileName=data.url
        var myArr = myfileName.split("/");
        var myAudioName=myArr[myArr.length-1]+".wav";
        setrecordName(myAudioName);
        convertToBase64(data);
		
	  }

    const gotopage = () => {
        history.push('/playtone');
    }
    const createTimer=()=>{
        var sec = 6;
        var a=setInterval(()=>{
          //this.setState({recorderTimer:--sec%60})
          var b=--sec
          setrecorderTimer(b)
          
        }, 1000);
        
        setTimeout(()=> {
            stop();
            //onStop();
          clearInterval(a);
      }, 6000);
      
    }
    const convertToBase64=(file)=>{
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();
      
            // Convert the file to base64 text
            reader.readAsDataURL(file.blob);
      
            // on reader load somthing...
            reader.onload = () => {
              // Make a fileInfo Object
              
              baseURL = reader.result;
              
              setbase64File(baseURL);
              resolve(baseURL);
            };
            
            
          });
        
      }
      const getMyRecord=()=>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
        axios.get(jsonConfig.apibaseurl + "customer/audio/list",{
            headers: headers
        }).then((response) => {
            setcustomSlNo(response.data.data.length)
        }, (error) => {


        })
    }

    
      const uploadAudio=()=>{
            var recordData={
                "serial_no":customSlNo,
                "audio":base64File,
                "voice_text":""
              }

              const headers = {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' + token
              }
            axios.post(jsonConfig.apibaseurl + "customer/audio/upload",recordData,{
                headers: headers
            }).then((response) => {
                
                if(response.data.status){
                    NotificationManager.success(response.data.msg);
                    getMyRecord();
                    setTimeout(()=> {
                        history.push({ pathname: "/createtone", state: { tone_id: location.state.tone_id } });
                      }, 1500);
                    
                  }
                  else{
                    NotificationManager.error(response.data.msg);
                  }
                
            }, (error) => {
                
    
            })
      }
      useEffect(() => {
        if(location.state===undefined){
          history.push('/tonelibery');
        }
        getMyRecord();
  }, []);
    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">
						
						
                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Sound Recorder</h6>
                        <h2>Record your audio</h2>
                        <p>Upload or Record your track separately. Then create your beautiful audio.</p>
                    </div>
                <div className="col-sm-7 mx-auto text-center audio-sec">
                    <h4>0{recorderTimer}:00</h4>
                    <p>{recordName}</p>
                    {/* <img src={Recordwave} className="img-fluid py-5 my-sm-5" alt=""/> */}
                    
                    <AudioReactRecorder
                        state={recordState}
                        onStop={onStop}
                        backgroundColor='rgb(255,255,255)'
                        canvasWidth={400}
                        canvasHeight={150}
                    />
                    {audioData ?(
                        <audio
                        id='audio'
                        controls
                        src={audioData ? audioData.url : null}
                        style={{width:'100%'}}
                        ></audio>
                    ):(
                        <div></div>
                    )}
                    {recorderStatus===false?(
                        <a onClick={start} className="audio-icon"> <FontAwesomeIcon icon={faMicrophone} /> </a>
                    ):(
                        <a onClick={stop} className="audio-icon"> <FontAwesomeIcon icon={faStop} /> </a>
                    )}
                    {recordName===''?(
                        <div></div>
                    ):(
                        <div className="audio-icons">
                            <div  className="savedel type_a"><FontAwesomeIcon icon={faTimes} /></div>
                            <div onClick={uploadAudio} className="savedel type_a"><FontAwesomeIcon icon={faCheck} /></div>
                        </div>
                    )}
                    
                    </div>
  
                               
            </div>
					

					
				</div>
			
			</div>
           

        </div>
        <NotificationContainer/>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Recordtone
