import React from 'react'
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCheck,faTimes,faPlay } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Recordwave from "../assets/images/recordwave.svg";



const Playtone = () => {

    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">
						
						
                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Sound Recorder</h6>
                        <h2>Record your audio</h2>
                        <p>Upload or Record your track separately. Then create your beautiful audio.</p>
                    </div>
                <div className="col-sm-7 mx-auto text-center audio-sec">
                    <h4>03:00</h4>
                    <p>Sound 01</p>
                    <img src={Recordwave} className="img-fluid py-5 my-sm-5" alt=""/>

                    <div className="audio-icons">

                        <a href="#" className="savedel"><FontAwesomeIcon icon={faTimes} /></a>
                        <a href="#" className="audio-icon"> <FontAwesomeIcon icon={faPlay} /> </a>
                        <a href="#" className="savedel"><FontAwesomeIcon icon={faCheck} /></a>
                    </div>

                    
                    </div>
  
                                
                            </div>
					

					
				</div>
			
			</div>
           

        </div>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Playtone
