import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Subscriptionbg from "../assets/images/subscription.svg";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { useHistory } from "react-router";
import {Modal} from 'react-bootstrap';



const Subscription = () => {
    const jsonConfig = require("../config/Config.json");
    const history = useHistory();
    const location = useLocation();
    const token=localStorage.getItem('authToken')
    const [mySubscription, setmySubscription] = useState(Array); 
    const [openModal, setopenModal] = useState(false);
    const [planId, setplanId] = useState("");
    const [planPrice, setplanPrice] = useState("");
    
    const gotoStripe=()=>{
        //history.push('/stripe');
        history.push({ pathname: "/stripe", state: { plan_id:planId,plan_price:planPrice } });
    }
    const gotoPaypal=()=>{
        history.push({ pathname: "/paypal", state: { plan_id:planId,plan_price:planPrice } });
    }

    const getSubscription=()=>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
        axios.get(jsonConfig.apibaseurl + "plan",{
            headers: headers
        }).then((response) => {
            
            setmySubscription(response.data.data)
        }, (error) => {


        })
    }
    useEffect(() => {
        getSubscription();
  }, []);
  const openPayModal=(planid,planprice)=>{
    setopenModal(true);
    setplanId(planid);
    setplanPrice(planprice);
  }
  const closeModal=()=>{
    setopenModal(false);
  }
    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">
						
						
                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Subscription</h6>
                        <h2>Upgrade to premium</h2>
                        <p>Your Pan3 account will be billed the end of your trial period
                    ( If applicable ) or on confirmation of your subscription</p>
                    </div>
                <div className="col-sm-7 mx-auto">

                <div className="subs-inner">
                    <img src={Subscriptionbg} className="img-fluid" alt=""/>
            {mySubscription.map((data,i)=>(
                <div key={i} className="type_a" onClick={()=>openPayModal(data.plan_id,data.plan_price)}>
                    <strong>${data.plan_price}</strong>
                    <h6>{data.plan_name}</h6>
                    <p>{data.plan_description}</p>
                </div>
            ))}
                
                
                </div>
                    
                    </div>
  
                                
                            </div>
					

					
				</div>
			
			</div>
           

        </div>
        <Modal 
            show={openModal} 
            onHide={closeModal}
            style={{marginTop:'10%'}}
            >
            <Modal.Header closeButton>
                <Modal.Title>Select Payment</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="d-sm-flex justify-content-center align-items-center pt-3">
                    <button 
                    type="button" 
                    className="rabutton mr-sm-4 mb-3 mb-md-0"
                    onClick={gotoPaypal}
                    >Pay with Paypal</button>
                        {/* <button 
                        type="button" 
                        className="ralogin"
                        onClick={gotoStripe}
                    >Pay with Stripe</button> */}

                    </div>
                </Modal.Body>
                
			</Modal>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Subscription
