import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Audiol from "../assets/images/Audiol.svg";
import Playicon from "../assets/images/playicon.svg";
import Pauseicon from "../assets/images/pauseicon.svg";
import Check from "../assets/images/check.svg";
import axios from 'axios';
import { useHistory } from "react-router";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {Modal} from 'react-bootstrap';


const Tonelibery = () => {
    const [tones, settones] = useState(Array);
    const [playStatus, setplayStatus] = useState(true);
    const [playUrl, setplayUrl] = useState("");
    const [modalOpen, setmodalOpen] = useState(false);
    const jsonConfig = require("../config/Config.json");
    const history = useHistory();

    const getTones=()=>{
        axios.get(jsonConfig.apibaseurl + "tonelist/audio")
        .then((response) => {
            
            if (response.data.status) {
                settones(response.data.output);
                
            } else {
                //NotificationManager.error(response.data.msg);
            }
        }, (error) => {


        })
    }
    useEffect(() => {
        getTones();

    }, []);
    const selectTone=(tone_id)=>{
        history.push({ pathname: "/createtone", state: { tone_id: tone_id } });
    }
    const playAudio=(toneUrl)=>{
        setmodalOpen(true);
        setplayUrl(toneUrl)
    }
    const closeModal=()=>{
        setmodalOpen(false);
        setplayUrl("")
    }
    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 col-md-10 mx-auto">
                <div className="loginbox">
						
						
                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Select Tone</h6>
                        <h2>Frequency and tone libery</h2>
                        <p>Please go through the tones and pick the one that you like the best. 
                  Then press the check to select. Simple.</p>
                    </div>
                <div className="col-sm-8 col-md-10 mx-auto">
                    <ul className="pt-3 pl-sm-5 ourcomp">

                    {tones.map((e, key) => (
                        <li className="wow fadeIn" 
                            data-wow-offset="10"  
                            data-wow-iteration="1" 
                            data-wow-delay=".5s"
                            key={key}
                            >
                            <FontAwesomeIcon 
                                icon={faCircle} 
                                className="redcer type_a"
                                
                            />
                            <div className="audiolist d-flex justify-content-between align-items-center mb-2">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                        <img src={Audiol} alt="Audiol" className="img-fluid"/>
                                    </div>
                                    <div className="ml-4">
                                        <h4>{e.tonename}</h4>
                                        <span>{e.tone_length}:00</span>
                                    </div>

                                </div>
                                <div className="ml-auto mr-4">
                                    
                                    <img 
                                        src={Playicon} 
                                        alt="Audiol" 
                                        className="img-fluid type_a"
                                        onClick={()=>playAudio(e.tonefile)}
                                    />
                                </div>
                                <div className="check">
                                    <img src={Check} onClick={()=>selectTone(e.tone_id)} />
                                </div>
                            </div>

                        </li>
                    ))}
                    </ul>
                </div>
  
                                
                </div>
					

					
				</div>
			
			</div>
           

        </div>
        <Modal 
            show={modalOpen} 
            onHide={closeModal}
            style={{marginTop:'10%'}}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <audio
                    id='audio'
                    controls
                    src={jsonConfig.mediaUrl+'/media/'+playUrl}
                    style={{width:'100%'}}
                    ></audio>
                </Modal.Body>
                
			</Modal>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Tonelibery
