import React from 'react'
import Clint from '../components/Clint';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCog, faLightbulb, faInfinity,faCircle } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import ban20 from "../assets/images/Ban20.png";
import Ban6 from "../assets/images/Ban5.png"
import Featuerbg from "../assets/images/featuerbg.svg"
import Fea1 from "../assets/images/fea1.svg"
import Fea2 from "../assets/images/fea2.svg"
import Fea3 from "../assets/images/fea3.svg"
import Fea4 from "../assets/images/fea4.svg"


const solution = () => {
    return (
        <section className="position-relative overflow-hidden">
        <img src={Featuerbg} alt="Ban5" className="featuerbg" />
        <FontAwesomeIcon icon={faCircle} className="racercle slide-bottom" />
        <FontAwesomeIcon icon={faCircle} className="ralinecercle slide-right" />
        <img src={Ban6} alt="Ban5" className="ban6 slide-bottom " />

            <div className="container">
            <Animated animationIn="fadeInDown" animationOut="fadeOut" animationInDuration={2000} animationOutDuration={2000} isVisible={true}>
            <div className="text-center sertitle mb-5 wow fadeInDown" data-wow-duration="2s">
            <h2>Features</h2>
            <h3>Features you can’t miss</h3>
            <p className="w-50 mx-auto">
                Create a free account, 
                the app allows you to record your personal affirmations 
                or upload pre-recorded ones.  
                easy to use, tone library with a variety of tones and frequencies, 
                all your mind tracks are saved. New frequencies added weekly.
            </p>
            </div>
            </Animated>
            <div className="row pt-sm-5 justify-content-between position-relative">
                <div className="col-lg-4 mbr-col-md-10">
                <div className="wrap">
                <div className="ico-wrap">
                <img src={Fea1} alt="Fea1" className="mbr-iconfont" />
                </div>
                <div className="text-wrap">
                <h2>Record Your Voice</h2>
                <p>After choosing your tone you will be allowed to create 3 affirmations by pressing the microphone icon.</p>
                </div>
                </div>
                <div className="clearfix"></div>

                    <div className="wrap mspace">
                    <div className="ico-wrap">
                    <img src={Fea2} alt="Fea2" className="mbr-iconfont" />
                    </div>
                    <div className="text-wrap">
                    <h2>Happiness frequency</h2>
                    <p>Choose from our library of tones/frequencies which are specially designed to relax the mind. </p>
                    </div>
                    </div>

                    <div className="clearfix"></div>

                        <div className="wrap">
                        <div className="ico-wrap">
                        <img src={Fea3} alt="Fea3" className="mbr-iconfont" />
                        </div>
                        <div className="text-wrap">
                        <h2>Meditation by your sound</h2>
                        <p>Once your recorded affirmations are 
                            created through the app along with your 
                            chosen tone you know have a personal mind 
                            track that you can listen to repeatedly.  
                            Your personal mind track is what you use to meditate to.</p>
                        </div>
                        </div>
                        
                </div>
                <img src={ban20} alt="Ban" className="ban20 img-fluid" />

                <div className="col-lg-4 mbr-col-md-10">
                <div className="wrap">
                <div className="ico-wrap">
                <img src={Fea4} alt="Fea4" className="mbr-iconfont" />
                </div>
                <div className="text-wrap">
                <h2>Record Your Voice</h2>
                <p>After choosing your tone you will be allowed to create 3 affirmations by pressing the microphone icon.</p>
                </div>
                </div>

                    <div className="wrap mspacer">
                    <div className="ico-wrap">
                    <img src={Fea1} alt="Fea1" className="mbr-iconfont" />
                    </div>
                    <div className="text-wrap">
                    <h2>Happiness frequency</h2>
                    <p>Choose from our library of tones/frequencies which are specially designed to relax the mind.</p>
                    </div>
                    </div>


                        <div className="wrap">
                        <div className="ico-wrap">
                        <img src={Fea2} alt="Fea2" className="mbr-iconfont" />
                        </div>
                        <div className="text-wrap">
                        <h2>Meditation by your sound</h2>
                        <p>Once your recorded affirmations are created 
                            through the app along with your chosen tone 
                            you know have a personal mind track that you 
                            can listen to repeatedly.  
                            Your personal mind track is what you use to meditate to.</p>
                        </div>
                        </div>
                </div>

            </div>
            </div>
            <Clint/>
        </section>
    )
}

export default solution
