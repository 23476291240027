import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import NavbarLogin from "../components/NavbarLogin";
import Footer from "../components/Footer";
import Facebook from "../assets/images/fb.svg";
import Sgoogle from "../assets/images/google.svg";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import axios from 'axios';
import { useHistory } from "react-router";
import {NotificationContainer, NotificationManager} from 'react-notifications';

const LoginRegister = () => {

    const [userEmail, setUserEmail] = useState("");
    const [userpassword, setUserPassword] = useState("");

    const [regUserName, setregUserName] = useState("");
    const [regUserEmail, setregUserEmail] = useState("");
    const [regUserPass, setregUserPass] = useState("");
    const [regUserConfPass, setregUserConfPass] = useState("");
    
    const jsonConfig = require("../config/Config.json");
    const history = useHistory();

    const toggleForm = () => {
        const loginbox = document.querySelector('.loginbox');
        loginbox.classList.toggle('active');
    };

    const gotopage = () => {
        history.push('/forgotpassword');
    }

    const onemailChange = (event) => {
        setUserEmail(event.target.value.trim());
    }
    const onpassChange = (event) => {
        setUserPassword(event.target.value);
    }
    const handleSubmit=()=>{
        var loginData={
            customer_email : userEmail,
            customer_password : userpassword,
            fcmtoken : ''
        }
        axios.post(jsonConfig.apibaseurl + "customer/login", loginData)
        .then((response) => {
            if (response.data.status) {
                // if (response.data.verified) {
                //     history.push('/personalinformation')
                //     toast.success(response.data.msg);
                //    // NotificationManager.success(response.data.msg);
                // } else {
                //     history.push('/verifyotp')
                //     toast.warning(response.data.msg);
                // }
                const jsonValue = JSON.stringify(response.data.data)
                localStorage.setItem('authData', jsonValue);
                localStorage.setItem('authToken', response.data.token);
                //NotificationManager.success(response.data.msg);
                history.push('/tonelibery');
            } else {
                //toast.error(response.data.msg);
                NotificationManager.error(response.data.msg);
            }
        }, (error) => {


        })
    }
    const onRegUserNameChange=(event)=>{
        setregUserName(event.target.value);
    }
    const onRegUserEmailChange=(event)=>{
        setregUserEmail(event.target.value)
    }
    const onRegUserPassChange=(event)=>{
        setregUserPass(event.target.value)
    }
    const onRegUserConfPassChange=(event)=>{
        setregUserConfPass(event.target.value)
    }
    const submitRegister=()=>{
        let pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(regUserEmail);
        if(regUserName==='' || regUserPass===''){
            NotificationManager.error('Please fill up all the fields');
        }
        else if(!pattern){
            NotificationManager.error('Please enter valid email address');
        }
        else if(regUserConfPass!==regUserPass){
            NotificationManager.error('Password not match');
        }
        else{
            
            
                let jsonData = {
                    customer_name : regUserName,
                    customer_password : regUserPass,
                    customer_email : regUserEmail,
                    fcmtoken : ''
                  }
        
                  axios.post(jsonConfig.apibaseurl + "customer/signup", jsonData)
                .then((response) => {
                    if (response.data.status) {
                        const jsonValue = JSON.stringify(response.data.data)
                        localStorage.setItem('authData', jsonValue);
                        localStorage.setItem('authToken', response.data.token);
                        history.push('/tonelibery');
                        
                    } else {
                        NotificationManager.error(response.data.message);
                    }
                }, (error) => {
        
        
                })
            
            
        }
        
    }
    return (
        <>
        <NavbarLogin/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
					<div className="loginbox loginht">
						
		<div className="user signinBx">
                <div className="formBx mt-sm-5">
                    <h4>Sign in to Pan3</h4>
                    {/* <a href="#"><img src={Facebook} alt="Facebook" width="50" className="mr-3"></img></a>
                    <a href="#"><img src={Sgoogle} alt="Sgoogle" width="50"></img></a> 
                    <p><a href="#">Or use your email account</a> </p>*/}
                    
                        <div className="form-group">
                        <FontAwesomeIcon icon={faEnvelope} className="loginicon"/>
                            <input type="email" 
                            className="form-control" 
                            aria-describedby="emailHelp" 
                            placeholder="email"
                            value={userEmail} 
                            onChange={onemailChange}
                            />

                        </div>
                        <div className="form-group">
                        <FontAwesomeIcon icon={faLock} className="loginicon"/>
                            <input type="password" 
                            className="form-control" 
                            id="exampleInputPassword1" 
                            placeholder="Password"
                            value={userpassword} 
                            onChange={onpassChange}
                            />
                        </div>
                        <div className="form-group">
                            <a href="#" onClick={gotopage}  className="forgot">Forgot your password?</a>
                        </div>
                        <button 
                        className="btn sec-btn sec-btn-g"
                        onClick={handleSubmit}
                        >Sign In</button>
                        <div className="form-group d-lg-none mobile-signin-signup">
                         <p className="signup mt-2">
                             Don't have an account ?
                             <a href="#" onClick={toggleForm}>Sign Up</a>
                         </p>
                        </div>
                    

                </div>
                <div className="imgBx">
                    <div className="imgBx-content">
                        <h3>Hello, Friend!</h3>
                        <p>Enter your personal details
                            and start journey with us</p>
                        <a href="#" onClick={toggleForm} className="sec-btn sec-btn-w">Sign Up</a>
                    </div>
                </div>
            </div>
            <div className="user signupBx">
                <div className="imgBx">
                    <div className="imgBx-content">
                        <h3>Welcome Back!</h3>
                        <p>To keep connected with us please
                            Login with your personal info</p>
                        <a href="#" onClick={toggleForm} className="sec-btn sec-btn-w">Sign In</a>
                    </div>
                </div>
                <div className="formBx mt-sm-4">
                    <h4>Create Account</h4>
                    {/* <a href="#"><img src={Facebook} alt="Facebook" width="50" className="mr-3"></img></a>
                    <a href="#"><img src={Sgoogle} alt="Sgoogle" width="50"></img></a>
                    <p className="mb-0"><a href="#">Or use your email account</a> </p> */}
                    
                        <div className="form-group">
                        <FontAwesomeIcon icon={faUser} className="loginicon"/>
                            <input type="text" 
                            className="form-control" 
                            aria-describedby="emailHelp" 
                            placeholder="Name"
                            value={regUserName} 
                            onChange={onRegUserNameChange}
                            />

                        </div>
                        <div className="form-group">
                        <FontAwesomeIcon icon={faEnvelope} className="loginicon"/>
                            <input type="email" 
                            className="form-control" 
                            aria-describedby="emailHelp" 
                            placeholder="email"
                            value={regUserEmail} 
                            onChange={onRegUserEmailChange}
                            />

                        </div>
                        <div className="form-group">
                        <FontAwesomeIcon icon={faLock} className="loginicon"/>
                            <input type="password" 
                            className="form-control" 
                            id="exampleInputPassword1" 
                            placeholder="Password"
                            value={regUserPass} 
                            onChange={onRegUserPassChange}
                            />
                        </div>

                        <div className="form-group">
                        <FontAwesomeIcon icon={faLock} className="loginicon"/>
                            <input type="password" 
                            className="form-control" 
                            id="exampleInputPassword1" 
                            placeholder="Confirm Password"
                            value={regUserConfPass} 
                            onChange={onRegUserConfPassChange}
                            />
                        </div>

                        <button 
                        className="btn sec-btn sec-btn-g"
                        onClick={submitRegister}
                        >Sign Up</button>
                        <div className="form-group d-lg-none mobile-signin-signup">
                            <p className="signup mt-2">
                                Already have an account ?
                                <a href="#" onClick={toggleForm} >Sign In</a>
                            </p>
                        </div>
                    

                </div>
            </div>
					
					
					</div>
					

					
				</div>
			
			</div>
           

        </div>
        <NotificationContainer/>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default LoginRegister
