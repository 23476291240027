import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faEdit, faPenAlt, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import User from "../assets/images/client3.png";
import Camera from "../assets/images/camera.svg";
import { useHistory } from "react-router";
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {Modal} from 'react-bootstrap';
import { saveAs } from "file-saver";


const Profile = () => {

    const history = useHistory();
    const jsonConfig = require("../config/Config.json");
    const token=localStorage.getItem('authToken');
    const userData=JSON.parse(localStorage.getItem('authData'));
    const [myDownload, setmyDownload] = useState(Array);
    const [mySubscription, setmySubscription] = useState("");
    const [passmodalOpen, setpassmodalOpen] = useState(false);
    const [oldPass, setoldPass] = useState("");
    const [newPass, setnewPass] = useState("");
    const [confPass, setconfPass] = useState("");
    const [openNameModal, setopenNameModal] = useState(false);
    const [nameField, setnameField] = useState("");
    const [photoModal, setphotoModal] = useState(false);
    const [base64File, setbase64File] = useState("");

    const getMyDownload=()=>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
        axios.get(jsonConfig.apibaseurl + "mix/list",{
            headers: headers
        }).then((response) => {
            setmyDownload(response.data.data)
        }, (error) => {


        })
    }
    const getSubscription=()=>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
        axios.get(jsonConfig.apibaseurl + "customer/subscription/active",{
            headers: headers
        }).then((response) => {
            if(response.data.status){
                setmySubscription(response.data.data)
            }else{
                setmySubscription("")
            }
        }, (error) => {


        })
    }
    useEffect(() => {
        getMyDownload();
        getSubscription();
  }, []);
    const gotoSub = () => {
        history.push('/subscription');
    }
    const gotoDown=()=>{
        history.push('/downloadtone');
    }
    const openPasswordModal=()=>{
        setpassmodalOpen(true)
    }
    const passmodalClose=()=>{
        setpassmodalOpen(false)
    }
    const onOldPassChange=(event)=>{
        setoldPass(event.target.value)
    }
    const onNewPassChange=(event)=>{
        setnewPass(event.target.value)
    }
    const onConfPassChange=(event)=>{
        setconfPass(event.target.value);
    }
    const changePassword=()=>{
        setpassmodalOpen(false);
        setoldPass("");
        setnewPass("");
        setconfPass("");
        if(oldPass==='' || newPass==='' || confPass===''){
            
            NotificationManager.error('Please fill up all the field');
        }
        else if(newPass!==confPass){
            NotificationManager.error('Password does not match');
        }
        else{
            let jsonData = {
                old_password : oldPass,
                new_password : newPass
              }
              const headers = {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' + token
              }
              axios.post(jsonConfig.apibaseurl + "customer/change/password",jsonData,{
                headers: headers
            }).then((response) => {
                if(response.data.status){
                    NotificationManager.success(response.data.msg);
                }else{
                    NotificationManager.error(response.data.msg);
                }
            }, (error) => {
    
    
            })

        }
        
          
    }
    const openNameChangeModal=()=>{
        setopenNameModal(true)
    }
    const namemodalClose=()=>{
        setopenNameModal(false)
    }
    const onNameChange=(event)=>{
        setnameField(event.target.value);
    }
    const saveName=()=>{
        setopenNameModal(false)
        if(nameField==='')
        {
            NotificationManager.error('Please fill up the field');
        }
        else{
            let jsonData = {
                customer_name : nameField,
              }
              const headers = {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' + token
              }
              axios.post(jsonConfig.apibaseurl + "customer/profile/edit",jsonData,{
                headers: headers
            }).then((response) => {
                if(response.data.status){
                    NotificationManager.success(response.data.msg);
                    userData["customer_name"]=nameField
                    const jsonValue = JSON.stringify(userData) 
                    localStorage.setItem('authData', jsonValue);
                    history.push('/profile');
                }else{
                    NotificationManager.error(response.data.msg);
                }
            }, (error) => {
    
    
            })
        }
        
    }
    const openPhotoModal=()=>{
        setphotoModal(true)
    }
    const closePhotoModal=()=>{
        setphotoModal(false)
    }
    const uploadPhoto=(event)=>{
        var file = event.target.files[0];
        //setbase64File(file);
        convertToBase64(file);
    }
    const convertToBase64=(file)=>{
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();
      
            // Convert the file to base64 text
            reader.readAsDataURL(file);
      
            // on reader load somthing...
            reader.onload = () => {
              // Make a fileInfo Object
              
              baseURL = reader.result;
              
              setbase64File(baseURL);
              resolve(baseURL);
            };
            
            
          });
        
      }
    const savePhoto=()=>{
        setphotoModal(false);
        
        //const formData = new FormData();
        //formData.append("customer_image", base64File);
        let jsonData = {
            customer_image : base64File,
          }
          const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
          axios.post(jsonConfig.apibaseurl + "customer/profile/edit",jsonData,{
            headers: headers
        }).then((response) => {
            
            if(response.data.status){
                NotificationManager.success(response.data.msg);
                userData["customer_image"]=response.data.profile_pic_path
                const jsonValue = JSON.stringify(userData) 
                localStorage.setItem('authData', jsonValue);
                history.push('/profile');
            }else{
                NotificationManager.error(response.data.msg);
            }
        }, (error) => {


        })
    }
    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">

                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Profile Setting</h6>
                        <h2>My Profile</h2>
                        <p>By definition your affirmation will be personal to you, and specific to what you want to achieve or change, but the following examples may provide some inspiration</p>
                        
                    </div>
                <div className="col-sm-7 mx-auto">

                <div className="myprofile text-center py-4">
                <a href="#">
                    <FontAwesomeIcon icon={faPenAlt} className="edit" onClick={openNameChangeModal}/>
                </a>
                <a href="#" className="profileimg">
                <img 
                    src={jsonConfig.mediaUrl+userData.customer_image} 
                    className="rounded-circle"
                    style={{height:'100px',width:'100px'}}
                /> 
                <span className="camera">
                    <img src={Camera} width="30" height="30" onClick={openPhotoModal}/> 
                </span>
                </a>
                <h3>{userData.customer_name}</h3>
                <h4>{userData.customer_email}</h4>
                </div>

                <ul className="ourcomp helpbox">
                    {/* <li className="wow fadeIn" data-wow-offset="10"  data-wow-iteration="1" data-wow-delay=".5s">
                    <div className="audiolist d-block  mb-2"> 
                    <h4>My Home</h4>
                    </div>
                    </li> */}
                    {/* <li className="wow fadeIn" data-wow-offset="10"  data-wow-iteration="1" data-wow-delay=".55s">
                    <div className="audiolist d-block  mb-2"> 
                    <h4>Notification</h4>
                    </div>

                    </li> */}
                    <li className="wow fadeIn" data-wow-offset="10"  data-wow-iteration="1" data-wow-delay="1s">
                    <div className="audiolist d-block mb-2"> 
                        <h4 className="type_a" onClick={openPasswordModal}>Change Password</h4>
                    </div>
                    </li>
                    <li className="wow fadeIn" data-wow-offset="10"  data-wow-iteration="1" data-wow-delay="1.5s">
                    <div className="audiolist d-block  mb-2"> 

                    <h4 className="type_a" onClick={gotoDown}>My Download</h4>
                        <p className="mb-0 profilp">{myDownload.length} Audio Downloded</p>
                    </div>
                    </li>
                    <li className="wow fadeIn" data-wow-offset="10"  data-wow-iteration="1" data-wow-delay="1.5s">
                        <div className="audiolist d-block  mb-2"> 
                            <h4 className="type_a" onClick={gotoSub}>My Subscription</h4>
                            {mySubscription===''?(
                                <p className="mb-0 profilp">You don't have any subscription plan</p>
                            ):(
                                <p className="mb-0 profilp">
                                    {mySubscription.plan_name} ({mySubscription.plan_duration} Day(s) / {mySubscription.no_of_download===9999999||mySubscription.no_of_download==='9999999'?'Unlimited':mySubscription.no_of_download} Downloads)
                                </p>
                            )}
                        </div>
                    </li>
                    </ul>
                    </div>    
                </div>
				</div>	
			</div>
        </div>
        <Modal 
                show={passmodalOpen} 
                onHide={passmodalClose}
                style={{marginTop:'10%'}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <input type="password" 
                            className="form-control" 
                            aria-describedby="emailHelp" 
                            placeholder="Old Password"
                            onChange={onOldPassChange}
                        />
                    </div>
                    <div className="form-group">
                        <input type="password" 
                            className="form-control" 
                            aria-describedby="emailHelp" 
                            placeholder="New Password"
                            onChange={onNewPassChange}
                        />
                    </div>
                    <div className="form-group">
                        <input type="password" 
                            className="form-control" 
                            aria-describedby="emailHelp" 
                            placeholder="Confirm Password"
                            onChange={onConfPassChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="btn sec-btn sec-btn-g"
                        onClick={changePassword}
                        >Save</button>
                    
                </Modal.Footer>
			</Modal>
            <Modal 
                show={openNameModal} 
                onHide={namemodalClose}
                style={{marginTop:'10%'}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <input type="text" 
                            className="form-control" 
                            aria-describedby="emailHelp" 
                            placeholder={userData.customer_name}
                            onChange={onNameChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="btn sec-btn sec-btn-g"
                        onClick={saveName}
                        >Save</button>
                    
                </Modal.Footer>
			</Modal>

            <Modal 
                show={photoModal} 
                onHide={closePhotoModal}
                style={{marginTop:'10%'}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        
                        <input
                            type="file"
                            onChange={uploadPhoto}
                            //className="form-control" 
						/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="btn sec-btn sec-btn-g"
                        onClick={savePhoto}
                        >Save</button>
                    
                </Modal.Footer>
			</Modal>
            <NotificationContainer/>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Profile
