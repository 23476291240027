import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCog, faLightbulb, faInfinity,faCircle } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Howitbg from "../assets/images/history.svg";
import Howitbg2 from "../assets/images/history2.svg";
import How1 from "../assets/images/how1.png";
import How2 from "../assets/images/how2.png";
import How3 from "../assets/images/how3.png";
import How4 from "../assets/images/how4.png";




const Howitwork = () => {
    return (
        <>
        <div className="position-relative">
             <img src={Howitbg} alt="Howitbg" className="howban" />
        </div>
        <section className="position-relative py-5 mb-5 overflow-hidden howitwork">

        <img src={Howitbg2} alt="Howitbg" className="howban2" />

            <div className="container">
            <div className="text-center howtitle mb-3 wow fadeInDown" data-wow-duration="2s" data-wow-delay=".5s">
            <h2>Create tone</h2>
            <h3>How it work</h3>
            </div>
            <div className="row howstep">
                <div className="col-sm-3 text-center wow fadeInDown" data-wow-duration="2s" data-wow-delay=".55s">
                <img src={How1} alt="How1"  />
                <h2>Step 1</h2>
                <p>Login or register For create tone</p>
                </div>
                <div className="col-sm-3 text-center wow fadeInDown" data-wow-duration="2s" data-wow-delay="1s">
                <img src={How2} alt="How1"  />
                <h2>Step 2</h2>
                <p>Select & choose Your Tone</p>
                </div>
                <div className="col-sm-3 text-center wow fadeInDown" data-wow-duration="2s" data-wow-delay="1.5s">
                <img src={How3} alt="How1"  />
                <h2>Step 3</h2>
                <p>Upload your Recorded Affirmations</p>
                </div>
                <div className="col-sm-3 text-center wow fadeInDown" data-wow-duration="2s" data-wow-delay="2s">
                <img src={How4} alt="How1"  />
                <h2>Step 4</h2>
                <p>Preview & Download <br/> Your mix Audio</p>
                </div>
            </div>
            </div>
        </section>
        </>
    )
}

export default Howitwork
