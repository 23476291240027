import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Audiol from "../assets/images/Audiol.svg";
import Playicon from "../assets/images/playicon.svg";
import Download from "../assets/images/download-arrow.svg";
import { useHistory } from "react-router";
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {Modal} from 'react-bootstrap';
import { saveAs } from "file-saver";

const Downloadtone = () => {

    const jsonConfig = require("../config/Config.json");
    const history = useHistory();
    const token=localStorage.getItem('authToken');
    const [myDownload, setmyDownload] = useState(Array);
    const [previewUrl, setpreviewUrl] = useState("");
    const [modalOpen, setmodalOpen] = useState(false);

    const getMyDownload=()=>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
        axios.get(jsonConfig.apibaseurl + "mix/list",{
            headers: headers
        }).then((response) => {
            
            setmyDownload(response.data.data)
        }, (error) => {


        })
    }

    
    useEffect(() => {
        getMyDownload();
        
  }, []);
    
    const playPreview=(prevUrl)=>{
        setmodalOpen(true);
        setpreviewUrl(prevUrl);
        
    }
    const ModalClose=() =>{
        setmodalOpen(false);
        setpreviewUrl("");
    }
    const download = (libId,finalUrl) => {
        var myArr = finalUrl.split("/");
        var myAudioName=myArr[myArr.length-1];
        var fullUrl=jsonConfig.mediaUrl +finalUrl;
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
        var data={
            audio_id:libId
          }
          axios.post(jsonConfig.apibaseurl + "customer/download",data,{
            headers: headers
        }).then((response) => {
            
            if(response.data.status){
                saveFile(fullUrl,myAudioName);
            }
            else{
                NotificationManager.success(response.data.msg);
                setTimeout(()=> {
                    history.push('/subscription');
                  }, 1500);
                
            }
        }, (error) => {


        })
        
    }
    const saveFile = async(fullurl,name) => {
        saveAs(fullurl,name);
      };
    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">

                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Download Audio</h6>
                        <h2>Your mix Audio file</h2>
                        <p>Enjoy daily affirmations and your personal sound mind tracks Using Pan3 the I AM APP. Affirm a better you.</p>
                    </div>
                <div className="col-sm-7 mx-auto">
                    <ul className="ourcomp">
                    {myDownload.map((data,i)=>(
                        <li 
                        className="wow fadeIn" 
                        data-wow-offset="10"  
                        data-wow-iteration="1" 
                        data-wow-delay=".5s"
                        key={i}
                        >
                            <FontAwesomeIcon icon={faCircle} className="bluecer" />
                            <div className="audiolist d-flex justify-content-between align-items-center mb-2">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div>
                                        <img 
                                            src={Playicon} 
                                            alt="Audiol" 
                                            className="img-fluid type_a"
                                            onClick={()=>playPreview(data.preview_voice,data.final_voice)}
                                        />
                                    </div>
                                    <div className="ml-4" onClick={saveFile}>
                                        <h4>Download {i+1}</h4>
                                        <span>01:00</span>
                                    </div>

                                </div>
                                <div>
                                    <a onClick={()=>download(data.customer_library_id,data.final_voice)}>
                                        <img src={Download} alt="Audiol" className="img-fluid"/>
                                    </a>
                                </div>
                            </div>
                        </li>
                    ))}
                        </ul>
                    </div>           
                </div>	
			</div>
		</div>
           

        </div>


        <Modal 
            show={modalOpen} 
            onHide={ModalClose}
            style={{marginTop:'10%'}}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <audio
                    id='audio'
                    controls
                    src={jsonConfig.mediaUrl+previewUrl}
                    style={{width:'100%'}}
                    ></audio>
                </Modal.Body>
                
			</Modal>
            <NotificationContainer/>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Downloadtone
