import React from 'react'
import ban from "../assets/images/Ban.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
// import { Animated } from "react-animated-css";
import Navbar from "../components/Navbar";
import Ban12 from "../assets/images/Ban12.png"
import Ban13 from "../assets/images/Ban13.png"
import Audiol from "../assets/images/Audiol.svg";
import Playicon from "../assets/images/playicon.svg";
import Ser1 from "../assets/images/ser1.svg";
import Ser2 from "../assets/images/ser2.svg";
import Ser3 from "../assets/images/ser3.svg";
import Ser4 from "../assets/images/ser4.svg";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const Services = () => {
    const history = useHistory();
    const checkLogin = () => {
        if (localStorage.getItem('authToken') === '' ||
            localStorage.getItem('authToken') === null ||
            localStorage.getItem('authToken') === 'null' ||
            localStorage.getItem('authToken') === undefined ||
            localStorage.getItem('authToken') === 'undefined') {
            // setbuttonText("Login Now");
            history.push('/signup');


        }
        else {
            // setbuttonText("Logout");
            history.push('/tonelibery');
        }

    }
    return (
        <>
            {/* <Navbar/> */}
            <section className="services" style={{ backgroundImage: `url(${ban})` }}>
                <div className="container">
                    <div className="wow fadeInDown text-center sertitle mb-5" data-wow-duration="2s">
                        <h2>Services We Deliver</h2>
                        <h3>Our Featured Services</h3>
                    </div>
                    <div className="row mb-sm-5">

                        <div className="col-lg-6 mbr-col-md-10">
                            <div className="wrap wow fadeIn" data-wow-offset="10" data-wow-iteration="1" data-wow-delay=".5s">
                                <div className="ico-wrap">
                                    <img src={Ser1} alt="Ser1" className="mbr-iconfont" />
                                </div>
                                <div className="text-wrap">
                                    <h2>Select & choose your Tone</h2>
                                    <p>After choosing your tone you will be allowed to create 3 affirmations by pressing the microphone icon.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mbr-col-md-10">
                            <div className="wrap wow fadeIn" data-wow-offset="10" data-wow-iteration="1" data-wow-delay=".75s">
                                <div className="ico-wrap">
                                    <img src={Ser2} alt="Ser2" className="mbr-iconfont" />
                                </div>
                                <div className="text-wrap">
                                    <h2>Record your Affirmations</h2>
                                    <p>Choose from our library of tones/frequencies which are specially designed to relax the mind.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mbr-col-md-10">
                            <div className="wrap wow fadeIn" data-wow-offset="10" data-wow-iteration="1" data-wow-delay="1s">
                                <div className="ico-wrap">
                                    <img src={Ser3} alt="Ser3" className="mbr-iconfont" />
                                </div>
                                <div className="text-wrap">
                                    <h2>Upload your Recorded Affirmations</h2>
                                    <p>After creating your personal “mind track” you will be able to listen at your leisure.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mbr-col-md-10">
                            <div className="wrap wow fadeIn" data-wow-offset="10" data-wow-iteration="1" data-wow-delay="1.5s">
                                <div className="ico-wrap">
                                    <img src={Ser4} alt="Ser4" className="mbr-iconfont" />
                                </div>
                                <div className="text-wrap">
                                    <h2>Preview & Download Your mix Audio</h2>
                                    <p>Listening to meditative tones for 10-15 minutes a day can help with anxiety and improve your over all mood.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row pt-5">
                        <div className="col-md-6">
                            <div className="position-relative pt-sm-5 mt-sm-5">
                                <img src={Ban12} alt="Ban" className="ban12 img-fluid" />
                                <img src={Ban13} alt="Ban2" className="ban13" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="sertitle " >
                                <div className="wow fadeInRight" data-wow-duration="2s">
                                    <h2>Select Tone</h2>
                                    <h3>Frequency and Tone Library</h3>
                                    <p>
                                        Our tone library offers a verity of tones specially 
                                        designed to relax your mind and put you in a meditative state.
                                    </p>
                                </div>

                                <ul className="pt-3 pl-sm-5 ourcomp">
                                    <li className="wow fadeIn" data-wow-offset="10" data-wow-iteration="1" data-wow-delay=".5s">
                                        <FontAwesomeIcon icon={faCircle} className="bluecer" />
                                        <div className="audiolist d-flex justify-content-between align-items-center mb-2">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div><img src={Audiol} alt="Audiol" className="img-fluid"></img></div>
                                                <div className="ml-4">
                                                    <h4>432Hz Frequency</h4>
                                                    <span>05:00</span>
                                                </div>

                                            </div>
                                            <div>
                                                <img src={Playicon} alt="Audiol" className="img-fluid"></img>
                                            </div>
                                        </div>

                                    </li>
                                    <li className="wow fadeIn" data-wow-offset="10" data-wow-iteration="1" data-wow-delay=".55s">
                                        <FontAwesomeIcon icon={faCircle} className="redcer" />
                                        <div className="audiolist d-flex justify-content-between align-items-center mb-2">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div><img src={Audiol} alt="Audiol" className="img-fluid"></img></div>
                                                <div className="ml-4">
                                                    <h4>432Hz Frequency</h4>
                                                    <span>05:00</span>
                                                </div>

                                            </div>
                                            <div>
                                                <img src={Playicon} alt="Audiol" className="img-fluid"></img>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="wow fadeIn" data-wow-offset="10" data-wow-iteration="1" data-wow-delay="1s">
                                        <FontAwesomeIcon icon={faCircle} className="bluecer" />
                                        <div className="audiolist d-flex justify-content-between align-items-center mb-2">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div><img src={Audiol} alt="Audiol" className="img-fluid"></img></div>
                                                <div className="ml-4">
                                                    <h4>432Hz Frequency</h4>
                                                    <span>05:00</span>
                                                </div>

                                            </div>
                                            <div>
                                                <img src={Playicon} alt="Audiol" className="img-fluid"></img>
                                            </div>
                                        </div>

                                    </li>
                                    <li className="wow fadeIn" data-wow-offset="10" data-wow-iteration="1" data-wow-delay="1.5s">
                                        <FontAwesomeIcon icon={faCircle} className="redcer" />
                                        <div className="audiolist d-flex justify-content-between align-items-center mb-2">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div><img src={Audiol} alt="Audiol" className="img-fluid"></img></div>
                                                <div className="ml-4">
                                                    <h4>432Hz Frequency</h4>
                                                    <span>05:00</span>
                                                </div>

                                            </div>
                                            <div>
                                                <img src={Playicon} alt="Audiol" className="img-fluid"></img>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                                <button type="button" onClick={checkLogin} className="ralogin d-block mx-auto" style={{ color: "#fff" }}>Create Tone</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Services
