import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, faPlus, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Audiol from "../assets/images/Audiol.svg";
import Playicon from "../assets/images/playicon.svg";
import Download from "../assets/images/download-arrow.svg";
import { useHistory } from "react-router";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const Issue = () => {

    const history = useHistory();
    const jsonConfig = require("../config/Config.json");
    const token=localStorage.getItem('authToken')
    const [issueList, setissueList] = useState(Array);
    const [modalOpen, setmodalOpen] = useState(false);
    const [issueid, setissueid] = useState("");
    const [issueTitle, setissueTitle] = useState("");
    const [issueText, setissueText] = useState("");

    const getAllIssue=()=>{
        axios.get(jsonConfig.apibaseurl + "issues/master").then((response) => {
            setissueList(response.data.data)
        }, (error) => {


        })
    }
    useEffect(() => {
        getAllIssue();
  }, []);
  const createIssue=(issueid,issueTitle)=>{
    setmodalOpen(true);
    setissueid(issueid);
    setissueTitle(issueTitle);
  }
  const modalClose=()=>{
    setmodalOpen(false);
    setissueid("");
    setissueTitle("");
  }
    const gotopage = () => {
        history.push('/subscription');
    }
const changeIssue=(event)=>{
    setissueText(event.target.value)
}
const raiseIssue=()=>{
    if(issueText===''){
        NotificationManager.error('Must fill up the description');
    }
    else{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
          var sendData = {
            issue_id : issueid,
            post_details : issueText,
            other_text:''
          }
        axios.post(jsonConfig.apibaseurl + "customer/issue",sendData,{
            headers: headers
        }).then((response) => {
            setmodalOpen(false);
            setissueid("");
            setissueTitle("");
            NotificationManager.success(response.data.msg);
            setTimeout(()=> {
                history.push('/myrequest')
              }, 1500);
        }, (error) => {
    
    
        })
    }
    
}
    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">

                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Issue</h6>
                        <h2>Please Choose Your Issue</h2>
                        <p>Raise a request regarding your problem</p>
                    </div>
                <div className="col-sm-8 mx-auto">
                    <ul className="ourcomp helpbox">
                    {issueList.map((data,i)=>(
                        <li 
                            className="wow fadeIn" 
                            data-wow-offset="10"  
                            data-wow-iteration="1" 
                            data-wow-delay=".5s"
                            key={i}
                            >
                            <div className="audiolist d-flex justify-content-start align-items-center mb-2"> 
                                <a 
                                    href="#" 
                                    className="iplus"
                                    onClick={()=>createIssue(data.issue_id,data.issue_text)}
                                    >
                                        <FontAwesomeIcon icon={faPlus}  />
                                    </a>
                                <h4>{data.issue_text}</h4>
                            </div>
                        </li>
                    ))}
                    </ul>
                    </div>
  
                                
                            </div>
					

					
				</div>
			
			</div>
           

        </div>
        <Modal 
                show={modalOpen} 
                onHide={modalClose}
                style={{marginTop:'10%'}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{issueTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <textarea 
                        rows="5" 
                        className="form-control" 
                        onChange={changeIssue}
                        placeholder="Type Your Description"
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="btn sec-btn sec-btn-g"
                        onClick={raiseIssue}
                        >Send</button>
                    
                </Modal.Footer>
			</Modal>
            <NotificationContainer/>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Issue
