
import React, { Component, useState, useEffect, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './Animation.css';
import './Responsive.css';
import WOW from 'wowjs';
import 'react-notifications/lib/notifications.css';
import 'audio-react-recorder/dist/index.css'
//pages
import AppRoute from './AppRoute';



const App=()=> {

  const disableBack=()=>{
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }

  useEffect(() => {
    disableBack();
    new WOW.WOW({
        live: false
    }).init();

}, []);
  return (
    <div className="App">
    <AppRoute>
      
    </AppRoute>
    </div>
  );
}

export default App;
