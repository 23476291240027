import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Successful from "../assets/images/Successful_purchase.gif";
import { useHistory } from "react-router";


const Thankyou = () => {

    const history = useHistory();
    const gotoHome=()=>{
        history.push('/')
    }
    return (
        <>
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">
                <div className="col-sm-7 mx-auto">
                    <div className="text-center thankyou">
                    <img src={Successful} alt="" width="400" />
                         <h2>Thank you!</h2>
                        <p>This is a example to belling for payment the subscription to invoice xpress your payment was processed Successfully.</p>
                        <button className="rabutton mx-auto" onClick={gotoHome}> Got to Home </button>
                    </div>
                    </div>
  
                                
                            </div>
					

					
				</div>
			
			</div>
           

        </div>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
    )
}

export default Thankyou
