import React, { useEffect } from 'react';
import {Animated} from "react-animated-css";
import {Link} from "react-router-dom";
import Logo from "../assets/images/logo.svg";
// import User from "../assets/images/client3.png";
import { useHistory } from "react-router";

const Navbar = () => {
  const jsonConfig = require("../config/Config.json");
  const userData=JSON.parse(localStorage.getItem('authData'));
  const history = useHistory();
  const token=localStorage.getItem('authToken');

  const logout=()=>{
    localStorage.removeItem('authData');
    localStorage.removeItem('authToken');
    localStorage.clear();
    history.push('/signup');
  }

  useEffect(() => {
    if(userData==='' || userData===null || userData==='null' || userData===undefined || userData==='undefined')
    {
      history.push('/')
    }
    
}, []);
  
    return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light ">
       <div className="container-fluid px-sm-5">
       <Animated animationIn="fadeInDown" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
       <a className="navbar-brand" href="#">
       <Link to="/"><img src={Logo} alt="Logo" /></Link>
       </a>
        </Animated>

  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ml-sm-5 ml-md-0 mr-auto">
      <li className="nav-item active">
        <a className="nav-link"><Link to="/">Home <span className="sr-only">(current)</span></Link></a>
      </li>
      <li className="nav-item">
      <Link to="/tonelibery" className="nav-link" > Create Tone </Link>
      </li>

      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Download
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <Link className="dropdown-item" to="/downloadtone" >My Download</Link>
          <Link className="dropdown-item" to="/prerecord" >Pre-recorded Audio</Link>
        </div>
      </li>

      <li className="nav-item">
      <Link to="/subscription" className="nav-link" > Subscription </Link>
      </li>
      {/* <li className="nav-item">
      <Link to="/thankyou" className="nav-link" > Thankyou </Link>
      </li> */}

      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Issue
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <Link className="dropdown-item" to="/issue" >My Issue</Link>
          <Link className="dropdown-item" to="/myrequest" >My Request</Link>
        </div>
      </li>

    </ul>

    <ul className="navbar-nav">
      <li className="nav-item"><Link to="/help"> Help </Link></li>
      <li className="nav-item" onClick={logout}><Link>Logout</Link></li>
      {userData==='' || userData===null || userData==='null' || userData===undefined || userData==='undefined'?( 
          <li></li>
         ):(
           
           <li className="nav-item">
            <Link to="/profile"> 
              <img src={jsonConfig.mediaUrl+userData.customer_image} width="45" height="45" className="rounded-circle"/> 
            </Link>
          </li>
         )} 
      
    </ul>
    
    

  </div>
  </div>
</nav>
      
    )
}

export default Navbar
