import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCog, faLightbulb, faInfinity,faCircle } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import fbg from "../assets/images/imgbg.png";
import Client1 from "../assets/images/client1.png"
import Client2 from "../assets/images/client2.png"
import Client3 from "../assets/images/client3.png"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

const Clint = () => {
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 3,
    
            }
        },
    };
    return (
        <section className="featureser">
        <div className="container">
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2000} animationOutDuration={2000} isVisible={true}>
        <div className="text-center sertitle wow fadeInDown" data-wow-duration="2s">
        <h2>Client Review</h2>
        <h3>Our clients love us</h3>
        </div>
        </Animated>

        <div className="pt-5">
            <OwlCarousel className="owl-theme featured "{...options} >
                <div className="item">
                <div className="clientreview">
                <img src={Client1} alt="Client1"/>
                <h5>Ashekur Rahman</h5>
                <h6>Art Dirrector</h6>
                <p>Lorem Ipsum is simply dummy text 
                of the printing and typesetting industry. 
                Lorem Ipsum has been the</p>
                </div>
  
                </div>
                <div className="item">
                <div className="clientreview">
                <img src={Client2} alt="Client2"/>
                <h5>Ashekur Rahman</h5>
                <h6>Art Dirrector</h6>
                <p>Lorem Ipsum is simply dummy text 
                of the printing and typesetting industry. 
                Lorem Ipsum has been the</p>
                </div>
   
                </div>
                <div className="item">
                <div className="clientreview">
                <img src={Client3} alt="Client3"/>
                <h5>Ashekur Rahman</h5>
                <h6>Art Dirrector</h6>
                <p>Lorem Ipsum is simply dummy text 
                of the printing and typesetting industry. 
                Lorem Ipsum has been the</p>
                </div>
 
                </div>
                <div className="item">
                <div className="clientreview">
                <img src={Client1} alt="Client1"/>
                <h5>Ashekur Rahman</h5>
                <h6>Art Dirrector</h6>
                <p>Lorem Ipsum is simply dummy text 
                of the printing and typesetting industry. 
                Lorem Ipsum has been the</p>
                </div>

                </div>
                <div className="item">
                <div className="clientreview">
                <img src={Client2} alt="Client2"/>
                <h5>Ashekur Rahman</h5>
                <h6>Art Dirrector</h6>
                <p>Lorem Ipsum is simply dummy text 
                of the printing and typesetting industry. 
                Lorem Ipsum has been the</p>
                </div>

                </div>
                <div className="item">
                <div className="clientreview">
                <img src={Client3} alt="Client3"/>
                <h5>Ashekur Rahman</h5>
                <h6>Art Dirrector</h6>
                <p>Lorem Ipsum is simply dummy text 
                of the printing and typesetting industry. 
                Lorem Ipsum has been the</p>
                </div>

                </div>
            </OwlCarousel>
        </div>

            </div>

    </section>
    )
}

export default Clint
