import React, { useState, useEffect } from 'react';
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
// import User from "../assets/images/client3.png";
import { useLocation } from "react-router-dom";
const NavbarLogin = () => {
  // const jsonConfig = require("../config/Config.json");
  const location = useLocation();
  // const userData = JSON.parse(localStorage.getItem('authData'));
  const [isSignUp, setisSignUp] = useState(false);
  const [backButton,setbackButton] = useState('/')


  useEffect(() => {
    if (location.pathname == '/signup') {
      setisSignUp(true)
    }
    else {
      setisSignUp(false)
    }
    if (location.pathname == '/'){
      setbackButton('http://mypan3.com/')
    }
    else{
      setbackButton('/')
    }
    console.log("PATHNAME", location.pathname);
  }, []);
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light ">
      <div className="container-fluid px-sm-5">
        <Animated animationIn="fadeInDown" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
          <a className="navbar-brand" href="#">
            <Link to="/"><img src={Logo} alt="Logo" /></Link>
          </a>
        </Animated>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto webapplog">
            <li className="nav-item active">
              <a className="ralogin" href={`${backButton}`}><span style={{ color: "#fff" }}>Back</span></a>
            </li>
            {isSignUp === false &&
              <li className="nav-item">
                <button type="button" className="rabutton mr-sm-4" style={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}>
                  <Link to="/signup" style={{ color: "#fff" }}> Login</Link>
                </button>
              </li>
            }
            {/*<li className="nav-item">
      <Link to="/tonelibery" className="nav-link" > Create Tone </Link>
      </li>
      <li className="nav-item ">
        <Link to="/tonelibery" className="nav-link" > Tone Libery </Link>
      </li>
      <li className="nav-item">
      <Link to="/downloadtone" className="nav-link" > Download </Link>
      </li>
      <li className="nav-item">
      <Link to="/prerecord" className="nav-link" > Pre</Link>
      </li>
      <li className="nav-item">
      <Link to="/subscription" className="nav-link" > Subscription </Link>
      </li>
      <li className="nav-item">
      <Link to="/issue" className="nav-link" > Issue </Link>
      </li> */}

          </ul>



        </div>
      </div>
    </nav>
  )
}

export default NavbarLogin
