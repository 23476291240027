import React, { Component, useState, useEffect, useRef } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser,faMicrophone } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Upload from "../assets/images/upload.svg";
import Record from "../assets/images/record.svg";
import Wave from "../assets/images/wave.svg";
import Wavei from "../assets/images/wavei.svg";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {Modal} from 'react-bootstrap';
import { useHistory } from "react-router";


const Createtone = () => {

    const jsonConfig = require("../config/Config.json");
    const history = useHistory();
    const location = useLocation();
    const token=localStorage.getItem('authToken')
    const userData=JSON.parse(localStorage.getItem('authData'));
    const [myRecord, setmyRecord] = useState(Array);
    const [modalOpen, setmodalOpen] = useState(false);
    const [base64File, setbase64File] = useState("");

    const getMyRecord=()=>{
        const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
        axios.get(jsonConfig.apibaseurl + "customer/audio/list",{
            headers: headers
        }).then((response) => {
            setmyRecord(response.data.data)
        }, (error) => {


        })
    }

    useEffect(() => { 
        if(location.state===undefined){
            history.push('/tonelibery');
        }
        getMyRecord();
  }, []);
  
    const gotopage = () => {
        if(myRecord.length>=3){
            NotificationManager.info('You have already added 3 records');
        }
        else{
            history.push({ pathname:'/recordtone',state: { tone_id: location.state.tone_id }});
        }
        
    }
    const openModalPop=()=>{
        setmodalOpen(true);
    }
    const uploadModalClose=()=>{
        setmodalOpen(false);
    }
    const uploadAudioFile=(event)=>{
        var file = event.target.files[0];
        
        setbase64File(file);
        //convertToBase64(file);
    }
    const convertToBase64=(file)=>{
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();
      
            // Convert the file to base64 text
            reader.readAsDataURL(file);
      
            // on reader load somthing...
            reader.onload = () => {
              // Make a fileInfo Object
              
              baseURL = reader.result;
              
              setbase64File(baseURL);
              resolve(baseURL);
            };
            
            
          });
        
      }
      const saveAudio=()=>{

            const formData = new FormData();
            formData.append("serial_no", myRecord.length);
			formData.append("audio", base64File);
			formData.append("voice_text", "");

          const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
        axios.post(jsonConfig.apibaseurl + "customer/audio/upload/phone",formData,{
            headers: headers
        }).then((response) => {
            
            if(response.data.status){
                setmodalOpen(false);
                NotificationManager.success(response.data.msg);
                getMyRecord();
              }
              else{
                setmodalOpen(false);
                NotificationManager.error(response.data.msg);
              }
            
        }, (error) => {
            

        })
      }
    const mixAudio=()=>{
        var toneid={
            tone_id:location.state.tone_id
           }
           const headers = {
            'Content-Type': 'application/json',
            'Authorization':  'Bearer ' + token
          }
          axios.post(jsonConfig.apibaseurl + "audio/mix",toneid,{
            headers: headers
            }).then((response) => {
            if(response.data.status){
                NotificationManager.success(response.data.msg);
                setTimeout(()=> {
                    history.push('/downloadtone')
                  }, 1500);
                
              }
              else{
                NotificationManager.error(response.data.msg);
              }
            
        }, (error) => {
            

        })
    }
    return (
                <>
        
        <Navbar/>
        <div className="position-relative overflow-hidden">
        <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})`}}>
        <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />
       
        <div className="container">
			<div className="row">
				<div className="col-sm-9 mx-auto">
                <div className="loginbox">
						
						
                        <div className="sec-head wow fadeInDown"  data-wow-duration="2s" data-wow-delay=".5s">
                        <h6>Your Audio</h6>
                        <h2>Upload or record your audio</h2>
                        <p>Upload or Record your track separately. Then create your beautiful audio.</p>
                    </div>
                <div className="col-sm-7 mx-auto">

                            <div className="upload-record-btn">
                <a onClick={openModalPop}>
                    <img src={Upload}  className="img-fluid" alt=""/>
                    <h6>Upload</h6>
                </a>
                <a onClick={gotopage}>
                    <img src={Record}  className="img-fluid" alt=""/>
                    <h6>Record</h6>
                </a>
            </div>
            {myRecord[0]&&
            <div className="upload-record-sec-inner active">
                <h5>Record 1</h5>
                <div className="upload-record-sec-inner-info">
                    <a href="#"><FontAwesomeIcon icon={faMicrophone} /></a>
                    <img src={Wave} className="img-fluid" alt=""/>
                    <span>06:00</span>
                </div>
            </div>
            }
            {!myRecord[0]&&
            <div className="upload-record-sec-inner">
                <h5>Add sound 1 </h5>
                <div className="upload-record-sec-inner-info">
                    <a href="#"><FontAwesomeIcon icon={faMicrophone} /></a>
                    <img src={Wavei}  className="img-fluid" alt=""/>
                    <span>06:00</span>
                </div>
            </div>
            }


            {myRecord[1]&&
            <div className="upload-record-sec-inner active">
                <h5>Record 2</h5>
                <div className="upload-record-sec-inner-info">
                    <a href="#"><FontAwesomeIcon icon={faMicrophone} /></a>
                    <img src={Wave} className="img-fluid" alt=""/>
                    <span>06:00</span>
                </div>
            </div>
            }
            {!myRecord[1]&&
            <div className="upload-record-sec-inner">
                <h5>Add sound 2 </h5>
                <div className="upload-record-sec-inner-info">
                    <a href="#"><FontAwesomeIcon icon={faMicrophone} /></a>
                    <img src={Wavei}  className="img-fluid" alt=""/>
                    <span>06:00</span>
                </div>
            </div>
            }
            {myRecord[2]&&
            <div className="upload-record-sec-inner active">
                <h5>Record 3</h5>
                <div className="upload-record-sec-inner-info">
                    <a href="#"><FontAwesomeIcon icon={faMicrophone} /></a>
                    <img src={Wave} className="img-fluid" alt=""/>
                    <span>06:00</span>
                </div>
            </div>
            }
            {!myRecord[2]&&
            <div className="upload-record-sec-inner">
                <h5>Add sound 3 </h5>
                <div className="upload-record-sec-inner-info">
                    <a href="#"><FontAwesomeIcon icon={faMicrophone} /></a>
                    <img src={Wavei}  className="img-fluid" alt=""/>
                    <span>06:00</span>
                </div>
            </div>
            }
            {myRecord.length===3 &&
            <button 
                className="create-audio-btn mx-auto mt-5"
                onClick={mixAudio}
            >Create Your Audio</button>
            }
        </div>                   
        </div>	
	</div>
			
</div>
           

        </div>
        <NotificationContainer/>
            <Modal 
                show={modalOpen} 
                onHide={uploadModalClose}
                style={{marginTop:'10%'}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Audio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        
                        <input
                            type="file"
                            onChange={uploadAudioFile}
                            //className="form-control" 
						/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="btn sec-btn sec-btn-g"
                        onClick={saveAudio}
                        >Save</button>
                    
                </Modal.Footer>
			</Modal>
        </section>

        <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
        <Footer/>
</div>



        </>
        
    )
}

export default Createtone
