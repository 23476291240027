import React from 'react'
import cont from "../assets/images/contact-iconimg.png";
import platfrm from "../assets/images/contact-banner.png";
import Ban6 from "../assets/images/Ban5.png";
import Star from "../assets/images/star.svg";
import Champ from "../assets/images/champ.svg";
import User from "../assets/images/user.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faStar,faCircle } from "@fortawesome/free-solid-svg-icons";

const Platfrom = () => {
    return (
        <section className="contact position-relative" style={{ backgroundImage: `url(${platfrm})`}}>

        <FontAwesomeIcon icon={faCircle} className="racercle slide-bottom" />
        <FontAwesomeIcon icon={faCircle} className="ralinecercle slide-right" />
        <img src={Ban6} alt="Ban5" className="ban6 slide-bottom " />


        <div className="contact-icon-img">
            <img src={cont} className="contact-iconimg slide-right" alt="refrsh" />
        </div>
        <div className="container text-center pb-5 jointext">
        <h3 className="w-sm-50 mx-auto mb-5">Join millions getting more mindful with Pan3</h3>
        <div className="row pb-sm-5 ">
        <div className="col-sm-4 ">
        <div className="joinbox ">
        <img src={Star} alt="How1" className="mt-4"/>
        <h4>4.9 Stars</h4>
        <p>Average rating</p>
        </div>
        </div>
        <div className="col-sm-4">
        <div className="joinbox">
        <img src={Champ} alt="How1"  />
        <h4>611.9K Ratings</h4>
        <p>On iOS and Google Play</p>
        </div>
        </div>
        <div className="col-sm-4">
        <div className="joinbox">
        <img src={User} alt="How1"  />
        <h4>1M Downloads</h4>
        <p>Across all platforms</p>
        </div>
        </div>

        </div>

        </div>
    </section>
    )
}

export default Platfrom
