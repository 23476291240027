import React, { useState } from 'react';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Securelogin from "../assets/images/Securelogin.gif";
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import NavbarLogin from "../components/NavbarLogin";

const Forgotpassword = () => {

    const jsonConfig = require("../config/Config.json");
    const [userEmail, setuserEmail] = useState("");

    const onemailChange = (e) => {
        setuserEmail(e.target.value)
    }
    const sendPassword = () => {
        let pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(userEmail);

        if (!pattern) {
            NotificationManager.error("Please enter valid email")
        }
        else {
            let jsonData = {
                customer_email: userEmail
            }
            axios.post(jsonConfig.apibaseurl + "customer/forgot/password", jsonData).then((response) => {
                NotificationManager.success(response.data.msg)
                // setTimeout(()=> {
                //     history.push('/signup')
                //   }, 1500);
            }, (error) => {
                NotificationManager.error("Email does not exsist")

            })
        }


    }
    // console.log(userEmail)
    return (
        <>
            <NavbarLogin />
            <div className="position-relative overflow-hidden">
                <section className="banner-sec signin-signup" style={{ backgroundImage: `url(${Loginbg})` }}>
                    <img src={Imgleft} alt="Imgleft" className="Imgleft slide-right" />

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-9 mx-auto">
                                <div className="loginbox">
                                    <div className="sec-head wow fadeInDown" data-wow-duration="2s" data-wow-delay=".5s">
                                        <h2>Forgot Password</h2>
                                        <p>Send new password to your Email ID</p>
                                    </div>
                                    <div className="col-sm-7 mx-auto forgot text-center">
                                        <img src={Securelogin} width="300"></img>
                                        <div className="form-group position-relative">
                                            <FontAwesomeIcon icon={faEnvelope} className="loginicon" />
                                            <input type="email"
                                                className="form-control"
                                                aria-describedby="emailHelp"
                                                placeholder=" Enter your Email"
                                                value={userEmail}
                                                onChange={onemailChange}
                                            />
                                        </div>
                                        <button
                                            className="create-audio-btn mx-auto mt-5"
                                            onClick={sendPassword}
                                        > Submit your Email</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NotificationContainer />
                </section>
                <img src={Imgright} alt="Imgright" className="Imgright slide-bottom" />
                <Footer />
            </div>



        </>
    )
}

export default Forgotpassword
