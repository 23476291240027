import React, { Component, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Loginbg from "../assets/images/loginbg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faLightbulb, faInfinity,faCircle,faUser, } from "@fortawesome/free-solid-svg-icons";
import {Animated} from "react-animated-css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Imgleft from "../assets/images/history.svg";
import Imgright from "../assets/images/history2.svg";
import Audiol from "../assets/images/Audiol.svg";
import Playicon from "../assets/images/playicon.svg";
import Pauseicon from "../assets/images/pauseicon.svg";
import Check from "../assets/images/check.svg";
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import qs from 'qs';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import {loadStripe} from '@stripe/stripe-js';

import {
    CardElement,
    Elements,
    useStripe,
    useElements,
    PaymentRequestButtonElement
  } from '@stripe/react-stripe-js';


  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentRequest, setPaymentRequest] = useState(null);

    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (elements == null) {
        return;
      }
      
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
      if (error) {
        console.log('[error]', error);
      } else {
        console.log('[PaymentMethod]', paymentMethod);

        const  pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
              label: 'Demo total',
              amount: 1099,
            },
            requestPayerName: true,
            requestPayerEmail: true,
          })
          pr.canMakePayment().then(result => {
            if (result) {
              setPaymentRequest(pr);
            }
            
          });
      }
    };

    if (paymentRequest) {
        return <PaymentRequestButtonElement options={{paymentRequest}} />
      }
        
    return (
        <form onSubmit={handleSubmit}>
          <CardElement />
          <button type="submit" disabled={!stripe || !elements}>
            Pay
          </button>
        </form>
      );
};

const stripePromise = loadStripe('pk_test_51Ivd6XSCT6iXCpICjpoAUbYuaQ5bnvwgXa2Y18WWRsvJvoJuLCACixiPzojbD7ApNuTh16YobXOJrbntU8nRJbkA00YxtRHCz4');

const Stripe = () => (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );

  export default Stripe

