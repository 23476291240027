import React, { Component, useState, useEffect, useRef } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {Switch } from 'react-router';
import HomePage from "./pages/index";
import LoginRegister from "./pages/signup";
import Tonelibery from "./pages/tonelibery";
import Createtone from "./pages/createtone";
import Recordtone from "./pages/recordtone";
import Playtone from "./pages/playtone";
import Downloadtone from "./pages/downloadtone";
import Subscription from "./pages/subscription";
import Help from "./pages/help";
import Forgotpassword from "./pages/forgotpassword";
import Issue from "./pages/issue";
import Profile from "./pages/profile";
import NotFoundPage from "./pages/404";
import myrequest from './pages/myrequest';
import Prerecord from './pages/prerecord';
import Stripe from './pages/stripe';
import Paypal from './pages/paypal';
import Thankyou from './pages/thankyou';
import { useHistory } from "react-router";


const AppRoute =()=> {
  
  const history = useHistory();
  const currentPathname = null;
  const currentSearch = null;
  
  const disableBack =()=>{
    // history.listen((newLocation, action) => {
    //   if (action === "PUSH") {
    //     if (
    //       newLocation.pathname !== this.currentPathname ||
    //       newLocation.search !== this.currentSearch
    //     ) {
    //       currentPathname = newLocation.pathname;
    //       currentSearch = newLocation.search;

    //       history.push({
    //         pathname: newLocation.pathname,
    //         search: newLocation.search
    //       });
    //     }
    //   } else {
    //     history.go(1);
    //   }
    // });
    window.history.forward();
  }
  useEffect(() => {
}, []);
    
        return (           
            <Router>
              
                        
              <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/home" component={HomePage} />
                  <Route exact path="/signup" component={LoginRegister} />
                  
                  <Route exact path="/tonelibery" component={Tonelibery} />
                  <Route exact path="/createtone" component={Createtone} />
                  <Route exact path="/recordtone" component={Recordtone} />
                  <Route exact path="/playtone" component={Playtone} />
                  <Route exact path="/downloadtone" component={Downloadtone} />
                  <Route exact path="/prerecord" component={Prerecord} />
                  <Route exact path="/subscription" component={Subscription} />
                  <Route exact path="/stripe" component={Stripe} />
                  <Route exact path="/paypal" component={Paypal} />
                  <Route exact path="/help" component={Help} />
                  <Route exact path="/issue" component={Issue} />
                  <Route exact path="/myrequest" component={myrequest} />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/thankyou" component={Thankyou} />
                  <Route exact path="/forgotpassword" component={Forgotpassword} />
                  <Route exact path="/404" component={NotFoundPage} />
                  <Route component={NotFoundPage} />
                  
              </Switch>
                           
            </Router>
           
        );
      }
export default AppRoute