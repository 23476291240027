import React, { Component, useState, useEffect, useRef } from 'react';
import {Animated} from "react-animated-css";
import Banimg from "../assets/images/banner1.svg";
import Banimg2 from "../assets/images/banner2.svg";
import Banimg3 from "../assets/images/banner3.svg";
import Banimg4 from "../assets/images/banner4.svg";
import Ban from "../assets/images/Ban.png";
import Ban2 from "../assets/images/Ban2.png";
import Ban3 from "../assets/images/Ban3.png";
import Ban4 from "../assets/images/Ban4.png";
import Ban5 from "../assets/images/Ban5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

const Banner = () => {
    const history = useHistory();
    const [buttonText, setbuttonText] = useState("Login Now");
    useEffect(() => {
        if(localStorage.getItem('authToken')==='' ||
        localStorage.getItem('authToken')===null ||
        localStorage.getItem('authToken')==='null' ||
        localStorage.getItem('authToken')===undefined ||
        localStorage.getItem('authToken')==='undefined')
        {
            setbuttonText("Login Now");
            
            
        }
        else{
            setbuttonText("My Profile");
        }
      }, []);
    const doLogin=()=>{

        //history.push('/signup');
        if(localStorage.getItem('authToken')==='' ||
        localStorage.getItem('authToken')===null ||
        localStorage.getItem('authToken')==='null' ||
        localStorage.getItem('authToken')===undefined ||
        localStorage.getItem('authToken')==='undefined')
        {
            setbuttonText("Login Now");
            history.push('/signup');
            
            
        }
        else{
            setbuttonText("Logout");
            console.log(buttonText)
            history.push('/profile');
        }
    }
    const checkLogin=()=>{
        if(localStorage.getItem('authToken')==='' ||
        localStorage.getItem('authToken')===null ||
        localStorage.getItem('authToken')==='null' ||
        localStorage.getItem('authToken')===undefined ||
        localStorage.getItem('authToken')==='undefined')
        {
            setbuttonText("Login Now");
            history.push('/signup');
            
            
        }
        else{
            setbuttonText("Logout");
            history.push('/tonelibery');
        }
        
    }
    return (
        <div className="position-relative">
        <img src={Ban} alt="Ban" className="ban " />
        <img src={Ban2} alt="Ban2" className="ban2 slide-right" />
        <img src={Ban3} alt="Ban3" className="ban3 slide-bottom " />
        <img src={Ban4} alt="Ban4" className="ban4 slide-bottom " />
        <img src={Ban5} alt="Ban5" className="ban5 slide-bottom " />
        <div className="container">
            <div id="carouselExampleIndicators" className="carousel slide mt-5 pt-5" data-ride="carousel">
            <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div className="carousel-inner">
            <div className="carousel-item active">
            <div className="row">
            <div className="col-sm-6 order-md-1 order-2">
            <div className="bannertext">
            <h1>My Mind My Voice</h1>
            <p>Using your own voice along with a meditation tone you are able to create a personalized Mind Track.The pan3 app creates the effect by sending your affirmations through your left and right ears separately.</p>
                <button type="button" className="rabutton mr-4" onClick={checkLogin}> <FontAwesomeIcon icon={faPlus} /> Create Tone</button>
                <button type="button" className="ralogin" onClick={doLogin}>{buttonText}</button>
            </div>
            </div>
            <div className="col-sm-6 order-md-2 order-1">
            <img src={Banimg} alt="bannerimg" className="img-fluid slide-left"></img>
            </div>

            </div>
            </div>
            <div className="carousel-item">
            <div className="row">
            <div className="col-sm-6  order-md-1 order-2">
            <div className="bannertext">
            <h1>Select Frequency and tone</h1>
            <p>Using your own voice along with a meditation tone you are able to create a personalized Mind Track.
                The pan3 app creates the effect by sending your affirmations through your left and right ears separately.</p>
                <button type="button" className="rabutton mr-4" onClick={checkLogin}> <FontAwesomeIcon icon={faPlus} /> Create Tone</button>
                <button type="button" className="ralogin" onClick={doLogin}>{buttonText}</button>
            </div>
            </div>
            <div className="col-sm-6 order-md-2 order-1">
            <img src={Banimg2} alt="bannerimg" className="img-fluid slide-left"></img>
            </div>

            </div>
            </div>
            <div className="carousel-item">
            <div className="row">
            <div className="col-sm-6 order-md-1 order-2">
            <div className="bannertext">
            <h1>Download your Frequency</h1>
            <p>Using your own voice along with a meditation tone you are able to create a personalized Mind Track.
                The pan3 app creates the effect by sending your affirmations through your left and right ears separately.</p>
                <button type="button" className="rabutton mr-4" onClick={checkLogin}> 
                    <FontAwesomeIcon icon={faPlus} /> Create Tone
                </button>
                <button type="button" className="ralogin" onClick={doLogin}>{buttonText}</button>
            </div>
            </div>
            <div className="col-sm-6 order-md-2 order-1">
            <img src={Banimg3} alt="bannerimg" className="img-fluid slide-left"></img>
            </div>

            </div>
            </div>
            <div className="carousel-item">
            <div className="row">
            <div className="col-sm-6 order-md-1 order-2">
            <div className="bannertext">
            <h1>Listening Personal Sound</h1>
            <p>Using your own voice along with a meditation tone you are able to create a personalized Mind Track.
                The pan3 app creates the effect by sending your affirmations through your left and right ears separately.</p>
                <button type="button" className="rabutton mr-4" onClick={checkLogin}> <FontAwesomeIcon icon={faPlus} /> Create Tone</button>
                <button type="button" className="ralogin" onClick={doLogin}>{buttonText}</button>
            </div>
            </div>
            <div className="col-sm-6 order-md-2 order-1">
            <img src={Banimg4} alt="bannerimg" className="img-fluid slide-left"></img>
            </div>

            </div>
            </div>
            </div>
            
            </div>


        </div>
           
        </div>
    )
}

export default Banner
